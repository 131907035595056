import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';

const WalletQRCode = ({ walletAddress }) => {
  const [qrCodeData, setQRCodeData] = useState('');

  useEffect(() => {
    // Generate the QR code data when the component mounts
    generateQRCodeData();
  }, [walletAddress]);

  const generateQRCodeData = () => {
    // Replace this with logic to handle different types of addresses (USDT, Bitcoin, etc.)
    // Ensure the address is properly formatted based on the wallet type
    // For demonstration purposes, let's assume it's a Bitcoin address
    const formattedAddress = walletAddress;

    setQRCodeData(formattedAddress);
  };

  return (
    <div>
      {qrCodeData && (
        <QRCode
          value={qrCodeData}
          size={256}
          fgColor="#000000" // Set the QR code foreground color
          bgColor="#ffffff" // Set the QR code background color
          level="L" // Set the error correction level (L, M, Q, H)
          renderAs="svg" // Render the QR code as SVG
        />
      )}
    </div>
  );
};

export default WalletQRCode;

import pdv from "../../assets/Avtron.pdf"


export default function cert (){
 
    // const pds = "../../assets/Merx.pdf"
    const googlePdfViewerUrl = `https://docs.google.com/viewer?url=https://avtroncrest.com/${encodeURIComponent(pdv)}&embedded=true`;

    return (

        < >
        <div style={{  height:"100%", width:"100%", overflow:"hidden"}}>
            {/* <embed src={pdv}  name="D5DA662AFDC106CA180CFE784F519AC8" 
             width="100%" height="100%" type="application/pdf" style={{overFlow:"hidden",position:"absolute",left:0, top:0,
             scrollBar:"hidden", frameBorder:"0"}}/> */}

<iframe
    src={googlePdfViewerUrl}
    frameBorder="0"
    scrolling="auto"
    height="100%"
    width="100%"
    style={{overFlow:"hidden",position:"absolute",left:0, top:0,
             scrollBar:"hidden", frameBorder:"0"}}
></iframe>


        </div>
        
        </>



    )
    }
import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png"
import "./otp.css"
import "./otp.scss"
import { AuthContext } from "../../authcontext/authContext";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {  Container,Col,
    Modal,
    ModalBody,
    ModalHeader,
    ModalTitle,
    ModalFooter,
     Row,Card,CardBody,CardTitle,FormControl,
     FormGroup, InputGroup,FormSelect,
      CardHeader, FormLabel } from 'react-bootstrap';



export default function  Otpverify(props){

  const { currentUser } = useContext(AuthContext);

    const [inputs, setInputs] = useState({
        otp: ""
        
      });


    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      };
    
      const navigate = useNavigate();
    
      const { Otpverify } = useContext(AuthContext);


    const handleClick = async (e) => {
        e.preventDefault();
        try {
          await Otpverify(inputs);
          navigate("/login")
        } catch (err) {
          
        }
      };
  
 const check = props.inputs
  console.log(check)
return (
    <>
    
 <Row style={{justifyContent:"center",}}>
    <Col Col sm={8}>
     <Card style={{padding:"5rem", marginTop:"10%", background: "linear-gradient(rgba(253 ,253 ,253, 0.87), rgba(164, 167, 165, 0.74))"}}>
        <CardBody style={{textAlign:"center", color:"white"}}>
            <img src={logo} style={{width:"170px", height:"44px", marginBottom:"1rem"}}/>
            <h4>
        To complete the registration process,
         please check your email: {currentUser.email}  inbox for the One-Time Password
          (OTP) we've sent. Enter the OTP to verify your Email  <br/>
             </h4>
                <input type="text" required 
               name="otp"
               onChange={handleChange}
               
               style={{width:"80%", marginTop:"2rem", padding:"7px", borderRadius:"5px"}}  autoFocus/>

              <input className="regy" style={{ cursor:"pointer"}} type="submit" value="Register" onClick={handleClick} />
        </CardBody>
     </Card>
    </Col>
 </Row>


    
    </>
)

}

import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Container,Col,
    Modal,
    ModalBody,
    ModalHeader,
    ModalTitle,
    ModalFooter,
     Row,Card,CardBody,CardTitle,FormControl,Form,FormGroup, InputGroup, CardHeader } from 'react-bootstrap';
import './pol.css'
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useState, useRef , useEffect} from 'react';
import { makeRequest } from '../../axios';
import { Link, useNavigate } from "react-router-dom";
import WalletQRCode from '../Qrgen/qrgen';


export default function Kpackp5() {

   

   const [wallets, setWallets] = useState([])

    const [selectedComponent, setSelectedComponent] = useState('method');
    const timy = new Date()
    const limo = timy.toDateString()
    const [sety, setSety] = useState(false)

    
  const textAreaRef = useRef(null);

  const handleCopyClick = () => {
    // Select the text in the text area
    textAreaRef.current.select();

    // Copy the selected text to the clipboard
    document.execCommand('copy');

    // Deselect the text area

    window.getSelection().removeAllRanges();
     setSety(true)
    
    
  };

  const [err, setErr] = useState(null);
  const handleClick = async (e) => {
    e.preventDefault();
    

    try {
      // await makeRequest.post("");

    
    navigate("/dashboard")
    } catch (err) {
      setErr(err.response.data);
    }
   };

    const done = ()=> {
      navigate('/dashboard')
     }
  
     const navigate = useNavigate();

     useEffect(() => {
      makeRequest.get("admin/wallet").then((res) => {
        setWallets(res.data)
      } 
      )
  
    }, []);

     const bitcoinWalletAddress = wallets[4]?.wallet_address;
     const textToCopy = wallets[4]?.wallet_address;

    return (

        <>
      <Container className='about_us__modern'  style={{marginTop:"5rem"}}>
      <Row >
        <div style={{display:"flex", justifyContent:"center"}}>
         <Col xs={6} md="8">
         <Card style={{ padding:"2px",width:"100%", backgroundColor:"#000000e3", borderRadius:"13px"}}>
                           <Card.Header as="h3" size="bg" style={{color:"whitesmoke", textAlign:"center"}}>
                            USDC ERC(20)  WALLET ADRESSS
                           </Card.Header>
                             <hr style={{color:"white", marginBottom:0}} />
                            <Card.Body style={{backgroundColor:"transparent", borderTop: "2px solid white", margin:"-.1rem", textAlign:"center"}}>
                            <Card.Title style={{display:"flex", justifyContent:"space-between", color:"whitesmoke", textAlign:"center"}}>
                            <Button style={sety ? {border:"none"}: {display:"none"}} variant="outline-light">copied</Button>
                            <input  style={{width:"100%", pointerEvents: "none", textAlign:"center", 
                            padding:"4px", fontSize:"18px", border:"none", borderRadius:"5px"}}
                             ref={textAreaRef} value={textToCopy}  type='text' /> <br/>
                                <Button style={{border:"none"}} variant="outline-light" onClick={handleCopyClick}>copy</Button>
                            </Card.Title> 
                            
                            <hr  className='text-light' />
                            <h3>Usdc Wallet QR Code</h3>
                            <div >
                            
                           <WalletQRCode walletAddress={bitcoinWalletAddress} />
                           </div>
                            
                             <div style={{display:"flex", justifyContent:"space-between"}}>
                            <Button variant="outline-success" size="lg" 
                          style={{width:"30%", marginBottom:"1.5rem", border:"none" }} onClick={done}>
                             Done</Button> 
                             <Button variant="outline-danger" size="lg" onClick={handleClick}
                          style={{width:"30%", marginBottom:"1.5rem", border:"none"}} >
                             Cancel</Button> 
                             </div>
                            </Card.Body> 
                </Card>
             </Col>
             </div>
             </Row>
          </Container>
        </>
    )
}